import React, { useEffect, useState } from 'react'
function View2() {

    const [backendData, setBackendData] = useState([{}])
    useEffect(() => {
    })
    return (
        <div>
        </div>
    )
}

export default View2;

// import React from 'react';
// import view2 from  './view2.css';
// import { useLocation } from 'react-router-dom';

// const View2 = () => {
//     const location = useLocation();
//     const { keyfob, keyfob2 } = location.state || {}; // Obtener los datos enviados

//     return (
//         <div>
//             <h1>Datos Recibidos</h1>
//             <p>Nombre: {keyfob}</p>
//             <p>Keyfob: {keyfob2}</p>
//         </div>
//     );
// };

// export default View2;
