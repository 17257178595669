// import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box'; 
// import Container from '@mui/material/Container';
import view1 from './view1.css';
import { CssBaseline, Container, Box, TextField, Button, Typography, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import KeyIcon from '@mui/icons-material/Key';
import CircularProgress from '@mui/material/CircularProgress'; // Icono giratorio
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';


const View1 = ({ props, fondo }) => {
    const { llave } = props;

    const [loading, setLoading] = useState(false);
    const [input1, setInput1] = useState(''); // Estado para el primer input
    const [input2, setInput2] = useState(''); // Estado para el segundo input
    const navigate = useNavigate();


    const consulta = async () => {
        try {
            const response = await fetch('http://localhost:5000/users'); // Asegúrate de que este sea el puerto y ruta correctos
            const data = await response.json();
            console.log(data); // Esto mostrará los resultados de la consulta
        } catch (error) {
            console.error('Error al hacer la solicitud:', error);
        }
    };


    const sendRequest = (e) => {

        e.preventDefault();
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            const dataToSend = { keyfob: input1, keyfob2: input2 }; // Datos de los inputs
            navigate('/view2', { state: dataToSend });
        }, 5000);

    }
    return (

        <div className="fondo" style={
            fondo
                ? {
                    backgroundImage: `url(${fondo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: '100vh'
                }
                : {}
        } >
            <Box
                sx={{
                    bgcolor: '#9406bc', //morado

                    // bgcolor: '#00000052',
                    // bgcolor: '#f5fbff52',
                    // bgcolor: '#cfe8fc',
                    height: '80vh',
                    backdropFilter: 'blur(4px)',
                    width: '70%',
                    borderRadius: '20px',
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    boxShadow: 20,
                    position: 'relative',
                    zIndex: 1
                }}
            >
                <img
                    src="/img/logo_2.png"
                    alt="Key icon"
                    style={{
                        position: 'absolute',
                        top: '-50px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '120px', // Ajusta el tamaño de la imagen según sea necesario
                        color: 'white',
                        zIndex: -1
                    }}
                />
                <form style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Typography variant="h2" sx={{ mb: 2, color: 'white' }}>
                        <i>{llave}</i>
                    </Typography>
                    <FormControl sx={{ width: '90%' }}>
                        <TextField
                            label="KeyFob"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(e) => setInput1(e.target.value)}
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <KeyIcon sx={{ fontSize: 30, color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        textAlign: 'center', // Centra el texto dentro del campo de entrada
                                        color: 'white',
                                        height: '90px',
                                        borderRadius: '20px',
                                        fontSize: '30px',
                                        letterSpacing: '2px',
                                    },
                                    inputProps: {
                                        onKeyDown: (event) => {
                                            if (event.key === ' ') {
                                                event.preventDefault(); // Previene que se ingresen espacios
                                            }
                                        },
                                    },
                                },
                                inputLabel: {
                                    sx: {
                                        color: 'white',
                                        fontSize: 20
                                    },
                                },
                            }}
                            sx={{
                                // Cambia el color del label cuando el campo está enfocado
                                '& label.Mui-focused': {
                                    color: 'rgba(255, 255, 255)', // Blanco con transparencia cuando el campo tiene focus
                                },

                                // Cambia el color del borde inferior cuando se usa el variante "underline"
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: 'rgba(0, 0, 0, 0.6)', // Negro con transparencia cuando el campo tiene focus (aplica solo a variantes "standard" o "underline")
                                },

                                // Aplica estilos cuando se usa el variante "outlined"
                                '& .MuiOutlinedInput-root': {
                                    // Borde por defecto (estado normal)
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.4)', // Blanco con transparencia en estado normal (sin hover ni focus)
                                    },

                                    // Borde cuando el mouse pasa sobre el campo (hover)
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.7)', // Blanco con más opacidad al hacer hover
                                    },

                                    // Borde cuando el campo está enfocado (focus)
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'rgba(0, 0, 0, 0.3)', // Negro casi sólido cuando el campo tiene focus
                                    },
                                },
                            }}
                        />

                        <TextField
                            id="input-with-sx"
                            label="KeyFob"
                            variant="standard"
                            fullWidth
                            margin="normal"
                            onChange={(e) => setInput2(e.target.value)}
                            slotProps={{
                                input: {
                                    sx: {
                                        textAlign: 'center',
                                        color: 'white',
                                        height: '90px'
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <KeyIcon sx={{ fontSize: 40, color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                },
                                inputLabel: {
                                    sx: {
                                        color: 'white',
                                        fontSize: 40
                                    },
                                },
                            }}
                        />
                        <br></br>
                        <br></br>
                        {
                            !loading ?
                                <Button
                                    variant="contained"
                                    onClick={sendRequest}
                                    fullWidth
                                    type="submit"
                                    sx={{
                                        height: '100px',
                                        fontSize: 40,
                                        background: '#ff9800',
                                    }}
                                >
                                    Enviar
                                </Button> :
                                <LinearProgress color='inherit' />
                            // <Button
                            //     variant="contained"
                            //     onClick={sendRequest}
                            //     fullWidth
                            //     disabled
                            //     type="submit"
                            //     sx={{
                            //         height: '100px',
                            //         fontSize: 40,
                            //         background: '#ff9800',
                            //     }}
                            // >
                            //     <CircularProgress /> 
                            // </Button>

                        }
                    </FormControl>
                </form>
                <Button
                    variant="contained"
                    onClick={consulta}
                    fullWidth
                    type="submit"
                    sx={{
                        height: '100px',
                        fontSize: 40,
                        background: '#ff9800',
                    }}
                >
                    Enviar
                </Button>
            </Box>
        </div>


        // Fondo 3
        // <div className="fondo">

        //     <Box
        //         sx={{
        //             bgcolor: '#f5fbff52',
        //             // bgcolor: '#cfe8fc',
        //             height: '50vh',
        //             backdropFilter: 'blur(4px)',
        //             width: '30%',
        //             borderRadius: '20px',
        //             padding: 4,
        //             display: 'flex',
        //             flexDirection: 'column',
        //             justifyContent: 'center',
        //             boxShadow: 3,
        //             position: 'relative',
        //         }}
        //     >
        //         <img
        //             src="/img/logo_2.png"
        //             alt="Key icon"
        //             style={{
        //                 position: 'absolute',
        //                 top: '-50px',
        //                 left: '50%',
        //                 transform: 'translateX(-50%)',
        //                 width: '120px', // Ajusta el tamaño de la imagen según sea necesario
        //                 color: 'white',
        //             }}
        //         />
        //         <form style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        //             <Typography variant="h6" sx={{ mb: 2, color: 'white' }}>
        //                 <i>{llave}</i>
        //             </Typography>
        //             <FormControl sx={{ width: '90%' }}>
        //                 <TextField
        //                     label="KeyFob"
        //                     variant="outlined"
        //                     fullWidth
        //                     margin="normal"
        //                     slotProps={{
        //                         input: {
        //                             startAdornment: (
        //                                 <InputAdornment position="start">
        //                                     <KeyIcon sx={{ fontSize: 24, color: 'white' }} />
        //                                 </InputAdornment>
        //                             ),
        //                             sx: {
        //                                 textAlign: 'center',
        //                                 color: 'white'
        //                             },
        //                         },
        //                         inputLabel: {
        //                             sx: {
        //                                 color: 'white' // Cambia el color del label a blanco
        //                             }
        //                         },
        //                     }}
        //                 />

        //                 <TextField
        //                     id="input-with-sx"
        //                     label="KeyFob"
        //                     variant="standard"
        //                     fullWidth
        //                     margin="normal"
        //                     slotProps={{
        //                         input: {
        //                             style: {
        //                                 textAlign: 'center',
        //                             },
        //                             startAdornment: (
        //                                 <InputAdornment position="start">
        //                                     <KeyIcon sx={{ fontSize: 24, color: 'white' }} />
        //                                 </InputAdornment>
        //                             ),
        //                             sx: {
        //                                 textAlign: 'center',
        //                                 color: 'white'
        //                             },
        //                         },
        //                         inputLabel: {
        //                             sx: {
        //                                 color: 'white' // Cambia el color del label a blanco
        //                             }
        //                         },
        //                     }}
        //                 />
        //                 <br></br>
        //                 <br></br>
        //                 <Button variant="contained" color="primary" fullWidth type="submit">
        //                     Enviar
        //                 </Button>
        //             </FormControl>
        //         </form>
        //     </Box>
        // </div>

        // caja 2
        // <div className="fondo">

        //     <Box
        //         sx={{
        //             bgcolor: '#f5fbff52',
        //             // bgcolor: '#cfe8fc',
        //             height: '50vh',
        //             backdropFilter: 'blur(4px)',
        //             width: '50%',
        //             borderRadius: '20px',
        //             padding: 4,
        //             display: 'flex',
        //             flexDirection: 'column',
        //             justifyContent: 'center',

        //             boxShadow: 3,
        //             position: 'relative',
        //             // backgroundImage: 'url(/img/fondo-amifit.png)', // URL de la imagen de fondo
        //             backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), 
        //             url(/img/fondo-amifit.png)`, // Capa de transparencia y la imagen
        //             backgroundSize: 'cover',
        //             backgroundPosition: 'center',
        //             backgroundRepeat: 'no-repeat',
        //             backdropFilter: 'blur(4px)',
        //             // backgroundSize: 'cover', // Ajusta la imagen para que cubra todo el fondo
        //             // backgroundPosition: 'center', // Centra la imagen
        //             // backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
        //         }}
        //     >
        //         <KeyIcon sx={{ fontSize: 100, position: 'absolute', top: '-50px', left: '45%', color: 'white' }} />
        //         <form style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        //             <Typography variant="h3" sx={{ mb: 2, color: 'white' }}>
        //                 <i>{llave}</i>
        //             </Typography>
        //             <FormControl sx={{ width: '90%' }}>
        //                 <TextField
        //                     label="KeyFob"
        //                     variant="outlined"
        //                     fullWidth
        //                     margin="normal"
        //                     slotProps={{
        //                         input: {
        //                             sx: {
        //                                 textAlign: 'center',
        //                                 color: 'white'
        //                             },
        //                         },
        //                         inputLabel: {
        //                             sx : {
        //                                 color: 'white' // Cambia el color del label a blanco
        //                             }
        //                         }
        //                     }}

        //                 />

        //                 <TextField
        //                     id="input-with-sx"
        //                     label="KeyFob"
        //                     variant="standard"
        //                     fullWidth
        //                     margin="normal"
        //                     InputProps={{
        //                         sx: {
        //                             textAlign: 'center',
        //                             color: 'white'
        //                         },
        //                         inputProps: {
        //                             style: { textAlign: 'center' },
        //                         },
        //                     }}
        //                     InputLabelProps={{
        //                         style: { color: 'white' }, // Cambia el color del label a blanco
        //                     }}
        //                 />
        //                 <br></br>
        //                 <br></br>
        //                 <Button variant="contained" color="primary" fullWidth type="submit">
        //                     Enviar
        //                 </Button>
        //             </FormControl>
        //         </form>
        //     </Box>
        // </div>



        // CAJA 1
        // <div className="fondo" style={{ backgroundImage: fondo ? `url(${fondo})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100vh', }} >

        //     <Box
        //         sx={{
        //             bgcolor: '#f5fbff52',
        //             // bgcolor: '#cfe8fc',
        //             height: '50vh',
        //             backdropFilter: 'blur(4px)',
        //             width: '50%',
        //             borderRadius: '20px',
        //             padding: 4, 
        //             display: 'flex',
        //             flexDirection: 'column', 
        //             justifyContent: 'center',

        //             boxShadow: 3,
        //             position: 'relative',
        //         }}
        //     >
        //         <KeyIcon sx={{ fontSize: 100, position: 'absolute', top: '-50px',left: '45%', color:'white' }} />
        //         <form style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        //             <Typography variant="h3" sx={{ mb: 2 }}>
        //                 <i>{llave}</i>
        //             </Typography>
        //             <FormControl sx={{ width: '90%' }}>
        //                 <TextField
        //                     label="KeyFob"
        //                     variant="outlined"
        //                     fullWidth
        //                     margin="normal"
        //                     slotProps={{
        //                         input: {
        //                             sx: {
        //                                 textAlign: 'center',
        //                             },
        //                         },
        //                     }}
        //                 />

        //                 <TextField
        //                     id="input-with-sx"
        //                     label="KeyFob"
        //                     variant="standard"
        //                     fullWidth
        //                     margin="normal"
        //                     InputProps={{
        //                         sx: {
        //                             textAlign: 'center', 
        //                         },
        //                         inputProps: {
        //                             style: { textAlign: 'center' }, 
        //                         },
        //                     }}
        //                 />
        //                 <br></br>
        //                 <br></br>
        //                 <Button variant="contained" color="primary" fullWidth type="submit">
        //                     Enviar
        //                 </Button>
        //             </FormControl>
        //         </form>
        //     </Box>

        //     {/* {lista.map(item => (
        //         <div key={item.Id} className={item.Color}>
        //             {item.Nombre}
        //         </div>
        //     ))} */}
        // </div>
    );
};

export default View1;
