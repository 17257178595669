import View1 from './Components/views/view1/view1';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import View2 from './Components/views/view2/view2';


function App() {
  const props = {

    llave: 'Por favor, ingresa la llave (KeyFob)'
  }
  // const lista = [
  //   { Id: 1, Nombre: 'Goku', Color: 'bg-warning' },
  //   { Id: 2, Nombre: 'Vegeta', Color: 'bg-primary' },
  //   { Id: 3, Nombre: 'Gohan', Color: 'bg-danger' },
  //   { Id: 4, Nombre: 'Cell', Color: 'bg-success' }
  // ];

  const fondo = '/img/fondo2.jpg';

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<View1 props={props} fondo={fondo} />} />
          <Route path="/view2" element={<View2 />} />
        </Routes>
      </Router>
      {/* <View1 props={props} fondo={fondo} />  */}
    </>
  );
}

export default App;
